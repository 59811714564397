body {
    margin: 0;
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.circle {
    display: flex;
    position: fixed;
    justify-content: center;
    right: 0;
    bottom: 0;
    height: auto;
    border: 4pt solid red;
    border-radius: 50%;
    margin: 20px 20px 20px 20px
}

.wra {}

.floating-button {
    position: fixed;
    right: 0;
    bottom: 0;
    width: min(5vw, 80px);
    height: auto;
    border: 0.5vw solid var(--highlight);
    border-radius: 50%;
    cursor: pointer;
    margin: 0px 4vw 2vw 0px;
}

.unselectable {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.App-header {
    background-color: #414444;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2vmin;
    color: white;
}

.term-outline {
    position: absolute;
    width: 95%;
    height: 95%;
    border: 2px solid #88f0fd;
    padding-top: 10px;
    white-space: nowrap;
    overflow-y: scroll;
}

.signle-input {
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.one-action {
    padding-left: 4px;
}

.static {
    display: inline-flex;
    width: fit-content;
    -moz-fit-content: fit-content;
}

.input-line {
    display: inline-flex;
    flex-grow: 1;
    width: 100%;
    /* border: 2px solid purple; */
    margin-right: 20px;
    margin-bottom: 2px;
}

::-webkit-scrollbar {
    display: none;
}

.term-initial {
    color: #43919B;
    /* annes@annes text color */
    padding-left: 2px;
}

#term-machine {
    padding-left: 0px !important;
}

.term-cwd {
    color: #88f0fd
}

.term-input {
    border: none;
    outline-width: 0;
    padding: 0;
    background: transparent;
    height: 3vh;
    width: 100%;
    align-self: center;
    /* pointer-events:none; */
}

.term-input[type="text"] {
    font-size: 2vmin;
    color: white;
}

.dollar {
    margin-right: 0.5vw;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.margin-0>* {
    margin: 0 !important;
}

.selectable>* {
    -webkit-user-select: text;
    /* Safari */
    -moz-user-select: text;
    /* Firefox */
    -ms-user-select: text;
    /* IE10+/Edge */
    user-select: text;
    /* Standard */
}

.stats {
    display: inline-flex;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
}

.rank-icon {
    justify-self: center;
    align-self: center;
    width: 2.5vw;
    height: auto;
}

.highlight-color {
    color: #88f0fd;
}

.dimmed-color {
    color: #858888;
}

canvas {}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit;
}