body,
html {
    --highlight: #88f0fd;
    /* font-family: Helvetica, sans-serif; */
}

.unselectable {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.sidenav {
    position: fixed;
    display: flex;
    border-right: 3px solid var(--highlight);
    width: 15vw;
    height: 100vh;
    overflow: hidden;
    background-color: #414444;
    flex-direction: column;
    transition: all 0.5s ease 0s;
}

.sidenav .name {
    color: white;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    font-size: 3vh;
    white-space: nowrap;
    margin-top: 10px;
    width: fit-content;
}

.name p {
    text-align: center;
    margin-bottom: 2px;
}

.sidenav i {
    font-size: 10vh;
    color: white;
}

.option i {
    padding-right: 2px;
    align-items: center;
}

.option p {
    font-size: 3vh;
    user-select: none;
    color: white;
    text-align: center;
}

.option {
    display: inline-flex;
    height: 15vh;
    width: 100%;
    align-items: center;
}

.option:hover {
    background-color: var(--highlight);
    cursor: pointer;
}

.collapse {
    position: absolute;
    width: 100%;
}

.collapse i {
    margin-left: auto;
    float: right;
    cursor: pointer;
}

#contact {
    position: absolute;
    bottom: 0;
}

#home {
    margin-top: 20px;
}

@media only screen and (max-width: 1600px) {
    .sidenav {
        width: auto;
    }
    .sidenav p,
    h1 {
        display: none;
    }
    #home {
        margin-top: 10vh;
    }
}


/*  */